#vanta-canvas {
  width: 100%;
  height: 100vh;
  min-height: 100%;
  z-index: -10;
  position: absolute;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}
