#home-container {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  color: white;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home h1 {
  font-weight: 200;
  font-family: "Titillium Web";
  letter-spacing: 2rem;
  font-size: 6rem;
}

#home h2 {
  margin-top: -8rem;
  font-weight: 900;
  letter-spacing: -0.6rem;
  font-size: 15rem;
}

#home-bottom-bar {
  display: flex;
  flex-direction: row;
  margin-top: -14rem;
}

#home-bottom-bar-div {
  box-shadow: 0 0 30px 0 rgba(188, 32, 32, 0.7);
  height: 1.2rem;
  width: 31rem;
  background-color: var(--accentRedColor);
}

#home-bottom-bar img {
  aspect-ratio: auto;
  height: 8rem;
}

#home h1 {
  -webkit-mask-image: linear-gradient(
    -75deg,
    rgba(0, 0, 0, 0.6) 30%,
    #000 50%,
    rgba(0, 0, 0, 0.6) 70%
  );
  -webkit-mask-size: 200%;
  animation: shine 2s infinite;
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #home {
    transform: scale(0.55);
  }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 1024px) {
  #home {
    transform: scale(0.9);
  }
}
