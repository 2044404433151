#projects-container {
  height: 100vh;
  width: 100vw;
  color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.projects-holder {
  flex-shrink: 0;
  margin: 2rem;
  padding: 2.5rem;
  border-radius: 2rem;
  display: flex;
  outline: 2px solid rgba(255, 255, 255, 0.1);
  outline-offset: -2px;

  backdrop-filter: blur(5px);
  background: transparent;
  overflow: hidden;
  transition: 0.3s ease-out;
  width: 80rem;
}

.projects-holder:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: scale(1.05);
}

.projects-icon {
  aspect-ratio: auto;
  display: inline;
  height: 25rem;
  margin-right: 3.5rem;
  border-radius: 1.2rem;
}

.projects-information-holder {
  margin: 0rem;
  display: flex;
  flex-direction: column;
}

.projects-name {
  font-size: 3.8rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.projects-desc {
  margin-top: 0.8rem;
  font-size: 1.9rem;
}

.projects-link-holder {
  margin-top: auto;
  margin-bottom: 1rem;
}

.projects-link {
  text-decoration: none;
  color: white;
  font-size: 2.5rem;
  font-weight: 600;
  background: var(--accentRedColor);
  padding: 0.6rem 1.5rem 0.6rem 1.5rem;
  border-radius: 1rem;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.projects-link:hover {
  box-shadow: 0 0 2rem 0 var(--accentRedColor);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.projects-status-ongoing {
  background-color: transparent;
  outline: tomato solid 0.5rem;
  color: tomato;
  font-weight: 800;
  border-radius: 1rem;
  font-size: 1.8rem;
  padding: 1rem;
  position: absolute;
  left: 83%;
  top: 76%;
}

.projects-status-completed {
  background-color: transparent;
  outline: #4cb050 solid 0.5rem;
  color: #4cb050;
  font-weight: 800;
  border-radius: 1rem;
  font-size: 1.8rem;
  padding: 1rem;
  position: absolute;
  left: 80%;
  top: 76%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #projects-holder-container {
    transform: scale(0.5);
    /* margin-top: -25rem; */
  }

  #projects-container {
    justify-content: center;
  }
}

@media only screen and (min-device-width: 480px) and (max-device-width: 1024px) {
  #projects-holder-container {
    transform: scale(0.85);
    /* margin-top: 2rem; */
  }
}
