#navbar-container {
  display: flex;
  position: absolute;
  align-items: center;
  text-align: center;
  height: 100vh;
}

#navbar-container-mobile {
  display: flex;
  position: absolute;
  justify-content: center;
  text-align: center;
  width: 100vw;
}

#navbar {
  padding: 0px;
  z-index: 1;
  outline: 2px solid rgba(255, 255, 255, 0.1);
  outline-offset: -2px;
  backdrop-filter: blur(5px);
  margin-left: 1.4rem;
  border-radius: 2rem;
  width: 10rem;
  height: 44rem;
}

#navbar-mobile {
  padding: 0px;
  z-index: 1;
  outline: 2px solid rgba(255, 255, 255, 0.1);
  outline-offset: -2px;
  backdrop-filter: blur(5px);
  background: transparent;
  overflow: hidden;
  margin-top: 1.4rem;
  border-radius: 2rem;
  width: 44rem;
  min-width: 44rem;
  height: 10rem;
}

#navbar-top-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11.4rem;
  border-radius: 2rem 2rem 0rem 0rem;
  background-color: var(--accentRedColor);
  box-shadow: 0 0 30px 0 rgba(188, 32, 32, 0.5);
}

#navbar-top-section img {
  aspect-ratio: auto;
  padding-top: 0.8rem;
  height: 6.5rem;
}

#navbar-top-section-mobile {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11.4rem;
  background-color: var(--accentRedColor);
  box-shadow: 0 0 30px 0 rgba(188, 32, 32, 0.5);
}

#navbar-top-section-mobile img {
  aspect-ratio: auto;
  margin-top: 0.5rem;
  margin-left: 0.7rem;
  height: 6.5rem;
}

.navbar-selector {
  position: absolute;
  margin-left: 9.4rem;
  height: 10rem;
  width: 0.6em;
  background-color: var(--accentRedColor);
  box-shadow: 0 0 30px 0 rgba(188, 32, 32, 0.5);
  transition: all 0.3s ease-out;
}

.navbar-selector-mobile {
  position: absolute;
  margin-top: -0.6rem;
  /* margin-left: 11.4rem; */
  width: 10rem;
  height: 0.6em;
  background-color: var(--accentRedColor);
  box-shadow: 0 0 30px 0 rgba(188, 32, 32, 0.5);
  transition: all 0.3s ease-out;
}

.navbar-button {
  color: white;
  display: flex;
  font-weight: bold;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 100%;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.navbar-button div {
  height: 10rem;
  font-family: "Titillium Web";
  width: 10rem;
  display: flex;
  visibility: hidden;
  position: absolute;
  margin-left: 23.2rem;
  align-items: center;
  color: transparent;
  text-align: left;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.navbar-button img {
  aspect-ratio: auto;
  height: 4rem;
  transition: all 0.2s ease-in;
}

.navbar-button:hover {
  box-shadow: 0 0 30px 0 rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.navbar-button:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}

.navbar-button:hover img {
  height: 4.7rem;
}

.navbar-button:hover div {
  visibility: visible;
  color: white;
}

.navbar-button-mobile-container {
  display: flex;
  position: absolute;
  height: 100%;
  flex-direction: row;
  margin-left: 11.4rem;
  margin-top: -10rem;
}

.navbar-button-mobile {
  color: white;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 100%;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.navbar-button-mobile img {
  aspect-ratio: auto;
  height: 4rem;
  transition: all 0.2s ease-in;
}

.navbar-button-mobile:hover {
  box-shadow: 0 0 30px 0 rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.navbar-button-mobile:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #navbar-mobile {
    transform: scale(0.85);
  }
}
