.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

/* simple - enter transition 300ms, exit 150ms */

/* .fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
} */

/* advanced - enter transition 800ms, exit 150ms */
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
  transform: translateY(-10px);
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms linear 150ms, transform 300ms ease-out 150ms;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 150ms linear, transform 150ms ease-in;
}
