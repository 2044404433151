@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900&display=swap%27);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:100,200,300,400,500,600,700,800,900&display=swap%27);

:root {
  --navbarColor: #181818;
  --navbarBorderColor: rgb(97, 23, 23);
  --backgroundColor: rgb(20, 20, 20);
  --accentRedColor: rgb(188, 32, 32);
  overflow: hidden;
}

html {
  font-size: 50%;
}

body {
  margin: 0;
  background-color: var(--backgroundColor);
  font-family: "Montserrat", serif;
}

/* Scroll */

::-webkit-scrollbar {
  width: 1.5rem;
}

::-webkit-scrollbar-thumb {
  background: rgba(44, 44, 44, 0.7);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(44, 44, 44);
}
